import * as React from 'react';
import { Component } from 'react';
import { RouteComponentProps, Link } from "react-router-dom";
import { withRouter, InvitationType, GLGlobal, GLUtil } from 'gl-commonui';
import { InvitationsComPage } from "@app/components";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import { GSAdminAction } from "@app/util";
import { PathConfig } from '@app/config/pathconfig';

@withRouter
export class RegionCoachPage extends Component<RouteComponentProps<any>> {
    clickedBtns = []  
    render() {
        const { match: { params: { regionId } } } = this.props;
        const fmtMsg = GLGlobal.intl.formatMessage;
        return (
            <InvitationsComPage
                refId={regionId}
                typeId={InvitationType.regionTrainer}
                typeTitle={fmtMsg({ id: GSAdminLocale.RegionTrainerTitle })}
                intl={getIntl()}
                clickedBtns={this.clickedBtns}
                addInvitationAction={GSAdminAction.InviteRegionTrainer}
                deleteInvitationAction={GSAdminAction.DeleteRegionTrainer}
                sendEmailAction={GSAdminAction.SendRegionTrainer}
            />
        )
    }
}

function getIntl() {
    return {
        Invite: GSAdminLocale.RegionTrainerInvite,
        InviteTitle: SchoolLocale.InvitationInviteRegionTrainer,
    }
}